<template>
  <v-card class="elevation-0 rounded-lg pb-1" tile flat>
    <v-toolbar flat prominent height="50px" color="#FAFAFA">
      <v-toolbar-title><v-icon>{{ icon }}</v-icon><span class="ml-2">{{ title }}</span></v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="px-2 primary white--text rounded text-body-1 mt-2">{{ tasks.length }}</span>
    </v-toolbar>
    <v-list class="pb-3 py-0 overflow-auto" two-line v-if="tasks.length" max-height="400px">
      <template v-for="(item, index) in tasks">
        <v-list-item
          :key="index"
          class="py-0"
          :style="`border-left: solid 4px ${item.state.sta_color} !important; border-bottom: solid 1px rgba(0,0,0,0.1) !important`"
          @click="edit(item)"
        >
          <v-list-item-content class="pa-0">
            <v-list-item-title>
              <span class="caption">{{ getShortTaskName(item) }}</span>
            </v-list-item-title>
            <v-list-item-subtitle class="caption font-italic">
              <ActionLabel v-for="action in item.types" :key="action.typ_id" :action="action" />
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-chip class="mb-1 caption" :class="defineTaskClass(item)" small outlined ><v-icon left>mdi-calendar-range</v-icon>{{ locDateFormat.formatLocale(item.tsk_started_at, 'fr') }}</v-chip>
            <v-chip
              small
              label
              outlined
              :color="item.state.sta_color"
            >
              {{ item.state.sta_label }}
            </v-chip>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
    <v-alert v-else color="info" class="ma-2" icon="mdi-alert">Aucune tâche</v-alert>
  </v-card>
</template>

<script>
import dateLocale from '@/services/dateLocale'
import TaskMixin from '@/mixins/TaskMixin'
import ActionLabel from '@/components/tasks/ActionLabel'

export default {
  mixins: [TaskMixin],
  components: {
    ActionLabel
  },
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: () => 'mdi-calendar-multiple-check'
    },
    tasks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      locDateFormat: dateLocale
    }
  },
  methods: {
    async edit (item) {
      item = await this.fetchTask(item.tsk_id)
      this.setTask(item)
    }
  }
}
</script>
