<template>
  <v-container fluid>
    <Loader :loading="loading" />
    <Error :show="false" text="Erreur lors du chargement des tâches." />
    <Alert :show="error" outlined text="Erreur lors du chargement des tâches." />
    <v-row>
      <v-col cols="12" md="4">
        <DashboardTasksList
          title="Dernière tâches"
          icon="mdi-calendar-clock"
          :tasks="lastTasks"
        />
      </v-col>
      <v-col cols="12" md="4">
        <DashboardTasksList
          title="Prestations Demandée"
          icon="mdi-star"
          :tasks="requestedTasks"
        />
      </v-col>
      <v-col cols="12" md="4">
        <DashboardTasksList
          title="Tâches planifiées"
          icon="mdi-calendar-check"
          :tasks="nextTasks"
        />
      </v-col>
      <v-col cols="12" md="4">
        <DashboardTasksList
          title="Prestations spéciales"
          icon="mdi-star"
          :tasks="customTasks"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="elevation-0 rounded-lg pb-1" tile flat>
          <v-toolbar flat prominent height="50px" color="#FAFAFA">
            <v-toolbar-title><v-icon>--</v-icon><span class="ml-2">Alerte</span></v-toolbar-title>
            <v-spacer></v-spacer>
            <span class="px-2 primary white--text rounded text-body-1 mt-2">Alerte</span>
          </v-toolbar>
          <v-list dense class="pb-3" v-if="alerts.length">
              <template v-for="(item, index) in alerts">
                  <v-list-item :key="index" class="pb-0" :href="(item.eve_lat) ? '/#/map/' + item.eve_lat + ',' + item.eve_lng : '/#/map/'">
                      <v-list-item-content>
                          <v-list-item-title :style="'color:' + item.sta_color + ';'">
                              <v-icon>{{ (item.eve_table_id == 'are') ? 'mdi-shape-square-plus' : 'mdi-map-marker-radius-outline' }}</v-icon> <span class="caption grey--text">{{ item.eve_title }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle class="caption font-italic">{{ item.eve_description }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="mt-1 mb-1">
                          <span class="caption">{{ $date(item.eve_date_start).format('DD/MM/YYYY') }}</span>
                          <v-chip small label outlined :color="typeState[item.eve_typ_id].typ_color">{{ typeState[item.eve_typ_id].typ_label }}</v-chip>
                      </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="'D-' + index"></v-divider>
              </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Loader from '@/components/ui/Loader'
import DashboardTasksList from '@/components/tasks/DashboardTasksList'
import Error from '@/components/ui/Error'
import Alert from '@/components/ui/Alert'
import TaskMixin from '@/mixins/TaskMixin'
import { mapGetters } from 'vuex'
import { taskUpdateService } from '@/rxjsServices';

export default {
  components: {
    Loader,
    DashboardTasksList,
    Error,
    Alert
  },
  mixins: [TaskMixin],
  data() {
    return {
      loading: false,
      error: false,
      tasks: [],
      alerts: [],
      paginalerts: { totalItems: 0 },
    }
  },
  computed: {
    ...mapGetters({
        typeState: 'app/typesById',
        stateState: 'app/statesById',
    }),
    lastTasks () {
      return this.formattedTasks.filter(t => (this.$date(t.tsk_started_at).isBefore(this.$date()) && t.tsk_sta_id !== 'STA01' ) )
    },
    nextTasks () {
      return this.formattedTasks.filter(t => this.$date(t.tsk_started_at).isAfter(this.$date()))
    },
    customTasks () {
      return this.formattedTasks.filter(t => t.tsk_custom)
    },
    requestedTasks () {
      return this.formattedTasks.filter(t => (this.$date(t.tsk_started_at).isBefore(this.$date()) && t.tsk_sta_id == 'STA01' ) )
    },
  },
  async mounted () {
    await this.loadData()
    this.loadAlert()
    this.taskService = taskUpdateService.getTask().subscribe(message => {
      if (message) {
        this.loadData()
      }
    })
  },
  methods: {
    async loadData() {
      try {
        this.error = false
        this.loading = true
        this.tasks = await this.fetchTasks('?tsk_sta_id=STA01,STA02,STA03,STA04&order_by=tsk_id&order_direction=desc')
      } catch (err) {
        this.error = true
      } finally {
        this.loading = false
      }
    },
    loadAlert(){
      let criteria = encodeURI('eve_sta_id=GEN01&eve_typ_id=%ALT%')
      this.$http.get( '/events/?' + criteria + '&page=1&per_page=5&order_by=eve_id&order_direction=desc&eve_sta_id=GEN01&eve_table_id=alt' ).then( (response) => {
          this.alerts.push(...response.data) //this.alerts = response.data
          if( response.data.meta ){
              this.paginEvent = Object.assign({}, this.paginEvent, response.data.meta.pagination)
          }
      })
      this.$http.get( '/events/?' + criteria + '&page=1&per_page=5&order_by=eve_id&order_direction=desc&eve_sta_id=GEN01&eve_table_id=are&extended=witharea' ).then( (response) => {
          response.data.forEach(element => {
            element.eve_lat = ( element.are_lat ) ? element.are_lat : element.eve_lat
            element.eve_lng = ( element.are_lng ) ? element.are_lng : element.eve_lng
          });
          this.alerts.push(...response.data)
          if( response.data.meta ){
              this.paginEvent = Object.assign({}, this.paginEvent, response.data.meta.pagination)
          }
      })
    }
  },
  beforeDestroy() {
    this.taskService.unsubscribe()
  }
}
</script>
