<template>
  <v-dialog
    v-model="open"
    persistent
    width="300"
  >
    <v-card
      color="white"
      class="pa-5"
    >
      <v-card-text class="text-center">
        <div class="my-5">
          <v-icon color="error">mdi-alert</v-icon>
        </div>
        <div v-html="text"></div>
      </v-card-text>
      <v-card-actions class="">
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="close"
        >
          Fermer
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: () => 'Une erreur est survenue.<br> Merci de réessayer ultérieurement.'
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      open: false
    }
  },
  watch: {
    show () {
      this.open = this.show
    }
  },
  methods: {
    close () {
      this.open = false
    }
  }
}
</script>
