<template>
  <v-dialog
    v-model="open"
    persistent
    width="300"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text>
        <div class="py-2">
          Chargement en cours
        </div>
        <v-progress-linear
          indeterminate
          color="white"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      open: false
    }
  },
  watch: {
    loading () {
      if (!this.loading) {
        setTimeout(() => {
          this.open = !this.open
        }, 200)
      } else {
        this.open = this.loading
      }
    }
  }
}
</script>
