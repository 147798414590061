<template>
  <v-alert
    v-show="open"
    class="my-4 white elevation-3"
    dismissible
    transition="fade-transition slide-y-transition"
    :class="fixed ? 'position-fixed' : ''"
    :type="variant"
    v-bind="$attrs"
  >
    {{ text }}
  </v-alert>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: () => 'Une erreur est survenue. Veuillez réessayer ultérieurement, merci.'
    },
    show: {
      type: Boolean,
      required: true
    },
    duration: {
      type: Number,
      required: false,
      default: () => 0
    },
    variant: {
      type: String,
      required: false,
      default: () => 'error'
    },
    fixed: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      open: false,
      timeoutHandler: null
    }
  },
  watch: {
    show: {
      handler () {
        this.open = this.show
        if (this.open && this.duration) {
          this.timeoutHandler = setTimeout(this.close, this.duration)
        }
      },
      immediate: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
      this.open = false
    }
  }
}
</script>

<style>
.position-fixed {
  position: fixed;
  top: 3rem;
  right: 2rem;
  z-index: 9999;
  max-width: 50vw;
  margin: auto;
}
</style>
